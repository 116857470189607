/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        const targetElement = document.querySelector('.gn-items');
        const disableBodyScroll = bodyScrollLock.disableBodyScroll;
        const enableBodyScroll = bodyScrollLock.enableBodyScroll;
        var controller = new ScrollMagic.Controller(),
            stickyHeader,
            stickyHeaderCTA,
            pinBanner,
            isMobile,
            isTablet,
            isDesktop,
            $document = $('.gn-wrap'),
            $header = $('header'),
            $navTrigger = $('.gn--trigger'),
            $burger = $('.burger'),
            $globalNav = $('.gn'),
            $anchorLink = $('.gn .hp-scroll a'),
            $navTopLevel = $('.gn-items'),
            $featBldImg = $('.blgd-img'),
            $featBldgTrgger = $('.bldg'),
            $bldgName = $('.content-trigger'),
            $homePageBodyClass = 'page-template-template-homepage',
            windowWidth = $(window).width();


        /*
        |----------------------------------------------------------------
        | Navigation
        |----------------------------------------------------------------
        */
        function gnOpen() {
          bodyScrollLock.disableBodyScroll(targetElement);
          $($globalNav).addClass('gn-open');
          $($header).addClass('remove-bg-colour');
        }

        function gnClose() {
          if( !$($navTopLevel).hasClass('move-off-gn') ) {
            $($globalNav).removeClass('gn-open');
          } else {
            $($globalNav).removeClass('gn-open');
            $($navTopLevel).removeClass('move-off-gn');
          }
          setTimeout(function(){
            $($header).removeClass('remove-bg-colour');
          }, 50);
          bodyScrollLock.enableBodyScroll(targetElement);
        }

        if($('body').hasClass($homePageBodyClass)) {
          $($anchorLink).on('click', function(e) {
            e.preventDefault()
            var urlAnchor = $(this).attr('href');
            urlAnchor = urlAnchor.substring(1);
            gnClose();
            $($navTrigger).removeClass('burger-active');
             var position = $(urlAnchor).offset().top - 70;
             $("HTML, BODY").animate({ scrollTop: position }, 700);
          });
        }

        function globalNav() {
          $($navTrigger).on('click', function(e) {
            $(this).toggleClass('burger-active');
            e.preventDefault();
            if ($($globalNav).hasClass('gn-open')) {
              gnClose();
            } else {
              gnOpen();
            }
          });
        }
        /*
        |---------------------------------------------- ------------------
        | Header & Burger Scroll
        |----------------------------------------------------------------
        */
          var headerTween = new TweenLite.to('.header',0.5, {css:{background: 'rgba(29, 37, 44, 1)' }, ease: Power0.easeNone});
          var headerCtaTween = new TweenLite.to('.cta-group',0.5, {css:{opacity: '1' }, ease: Power0.easeNone});

          if($('.page-banner').length) {
            stickyHeader = new ScrollMagic.Scene({
              triggerElement: '.theme-wrap',
              triggerHook: 0,
              duration: '300px',
              offset: '1200px'
            })
            .setTween(headerTween)
            .setClassToggle('.header', 'is-sticky')
            .addTo(controller);
          } else {
            stickyHeader = new ScrollMagic.Scene({
              triggerElement: '.theme-wrap',
              triggerHook: 0,
              duration: '100px',
              offset: '0'
            })
            .setTween(headerTween)
            .setClassToggle('.header', 'is-sticky')
            .addTo(controller);
          }







          if($('.page-banner').length) {
            stickyHeaderCTA = new ScrollMagic.Scene({
              triggerElement: '.theme-wrap',
              triggerHook: 0,
              duration: '300px',
              offset: '1200px'
            })
            .setTween(headerCtaTween)
            .addTo(controller);
          } else {
            stickyHeaderCTA = new ScrollMagic.Scene({
              triggerElement: '.theme-wrap',
              triggerHook: 0,
              duration: '100px',
              offset: '0'
            })
            .setTween(headerCtaTween)
            .addTo(controller);
          }

        /*
        |----------------------------------------------------------------
        | Animated Banner
        |----------------------------------------------------------------
        */
       var desktopAnimationIsActive;
       var bannerTl = new TimelineLite();

       function bannerAnimation() {
          if($('.page-banner').length) {
            bannerTl.to('.page-banner .bnr-content', 3, {css:{opacity: 0}, ease: Power0.easeNone}, '+=2.7')
              .to('.page-banner .bnr-overlay', 2, {opacity: 0}, '+=2')
              .to('.page-banner .bnr-img', 2, {filter: 'grayscale(0)'},  '-=2')
              .to('.header .main-logo', 2, {height: '75px', top: '10px'},  '-=2')
              .to('.header .text-logo', 2, {opacity: '0'},  '-=4')
              .to('.header', 2, {'padding-top': '10px', 'padding-bottom': '10px'},  '-=2');

            pinBanner = new ScrollMagic.Scene({
                triggerElement: '.theme-wrap',
                triggerHook: 0,
                duration: '120%',
            })
            .setTween(bannerTl)
            .setPin('.page-banner', {pushFollowers: true})
            .addTo(controller);
          } else {
            bannerTl.to('.header .main-logo', 2, {height: '75px', top: '10px'},  '-=2')
              .to('.header .text-logo', 2, {opacity: '0'},  '-=4')
              .to('.header', 2, {'padding-top': '10px', 'padding-bottom': '10px'},  '-=2');

            pinBanner = new ScrollMagic.Scene({
                triggerElement: '.theme-wrap',
                triggerHook: 0,
                duration: '100px',
            })
            .setTween(bannerTl)
            .addTo(controller);
          }
        }



        if(windowWidth > 767) {
          desktopAnimationIsActive = true;
          bannerAnimation();
        }

        $(window).resize(function(event) {
           width = $(window).width();
           if(width < 768 && desktopAnimationIsActive) {
              desktopAnimationIsActive = false;
              pinBanner = pinBanner.destroy(true);
           } else if ( width >= 768 && !desktopAnimationIsActive ) {
            desktopAnimationIsActive = true;
            bannerAnimation();
            }
        });

        /*
        |----------------------------------------------------------------
        | Flickity
        |----------------------------------------------------------------
        */
        //Flickity Fix
        var touchingCarousel = false,
            touchStartCoords;

        document.body.addEventListener('touchstart', function(e) {
         if (e.target.closest('.flickity-slider')) {
           touchingCarousel = true;
         } else {
           touchingCarousel = false;
           return;
         }

         touchStartCoords = {
           x: e.touches[0].pageX,
           y: e.touches[0].pageY
         };
        });

        document.body.addEventListener('touchmove', function(e) {
         if (!(touchingCarousel && e.cancelable)) {
           return;
         }

         var moveVector = {
           x: e.touches[0].pageX - touchStartCoords.x,
           y: e.touches[0].pageY - touchStartCoords.y
         };

         if (Math.abs(moveVector.x) > 7) {
           e.preventDefault();
         }

        }, {passive: false});
        //Flickity Fix End

        function contentSlider($slider, $dots, $buttons, $cellAlign, $height, $responsive, $scroll, $auto) {
          $($slider).flickity({
              wrapAround: false,
              imagesLoaded: true,
              pageDots: $dots,
              freeScroll: $scroll,
              prevNextButtons: $buttons,
              cellAlign: $cellAlign,
              watchCSS: $responsive,
              adaptiveHeight: $height,
              autoPlay: $auto,
              contain: true,
          });
        }

        contentSlider('.icon-content-blk .icon-slider', true, false, 'center', false, true, true, false);
        contentSlider('.client-slider', false, false, 'left', false, false, false, false);
        contentSlider('.tenant-slider', false, false, 'center', false, false, false, true);

        $('.client-slider').on('dragStart.flickity', function(){
          $('.client-slider').find('a').css({pointerEvents: 'none'});
        });
        $('.client-slider').on('dragEnd.flickity', function(){
          $('.client-slider').find('a').css({pointerEvents: 'all'});
        });


        /*
        |----------------------------------------------------------------
        | Building Hover
        |----------------------------------------------------------------
        */
        if($($featBldImg).length) {
          $($featBldgTrgger).on('click', function(e) {
            e.preventDefault();
            $(this).find('.trigger-icon').toggleClass('active');
            $(this).find('.content').fadeToggle(500);
            $(this).find($bldgName).toggleClass('hide-bg');
          });
        }

        /*
        |----------------------------------------------------------------
        | Execute the Functions
        |----------------------------------------------------------------
        */
        globalNav();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        window.addEventListener('load',function(){
            $.fn.matchHeight._update();
            $('.theme-wrap').addClass('show-theme');
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var $team = $('.team-grid li'),
            $showAllTeam = $('.more-team-btn'),
            windowWidth = $('body').width(),
            isMobileSize = true;
        /*
        |----------------------------------------------------------------
        | Team Members
        |----------------------------------------------------------------
        */
        function expandContent($gridName) {
          $($gridName).gridderExpander({
              scroll: false,
              scrollOffset: 120,
              scrollTo: "panel",
              animationSpeed: 950,
              animationEasing: "easeInOutExpo",
              showNav: true,
              nextText: "",
              prevText: "",
              closeText: " ",
          });
        }

        expandContent('.team-grid.gridder');
        expandContent('.values-grid.gridder');

        $($showAllTeam).on('click', function(e) {
          e.preventDefault();
          $($showAllTeam).remove();
          $($team).addClass('show-all');
          $.fn.matchHeight._update();
        });


        if(windowWidth > 767) {
          $($showAllTeam).remove();
          isMobileSize = false;

          $('.hp-vaules .values-grid li:eq(0)').click();
        }

        $(window).resize(function(event) {
          var width = $('body').width();

          if(width > 767 && isMobileSize) {
            isMobileSize = false;
            $($showAllTeam).remove();
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'service_template': {
      init: function() {
        var controller = new ScrollMagic.Controller(),
            ctaTl = new TimelineLite();



        //add second tween at time of 0 seconds
        ctaTl.to(".service-cta-wrap", 1, {marginBottom:0}, 0).to('.service-cta-nav', 1, {transform: 'translateY(0%)'}, 0);

        pinApproachSection = new ScrollMagic.Scene({
          triggerElement: '.service-cta-wrap',
          triggerHook: 0.4,
          duration: '500px',
        })
        .setTween(ctaTl)
        .addTo(controller);
      }
    },

    'featured_properties': {
      init: function() {
        var $caseStudyTrigger = $('.cs-trigger-wrap button'),
            $caseStudy = $('.case-study');

        $($caseStudyTrigger).on('click', function(e) {
          e.preventDefault();
          $($caseStudy).slideToggle();
        });
      }
    },
    // Contact Us
    'page_template_template_contact': {
      init: function() {

        /*
        |----------------------------------------------------------------
        | Google Maps
        |----------------------------------------------------------------
        */
        var $map = jQuery("#google-map");
        var bounds = new google.maps.LatLngBounds();

        var map = new google.maps.Map(document.getElementById('google-map'), {
            styles: $map.data('style') || {},
            zoom: 16,
            scrollwheel: false,
            mapTypeControl: false,
            draggable: true,
            zoomControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        });

        var location = {
            lat: parseFloat($map.data('lat')),
            lng: parseFloat($map.data('lng')),
            url: $map.data('url'),
        };

        var image = {
            url: $map.data('marker'),
            scaledSize: new google.maps.Size(32, 37),
        };

        var marker = new google.maps.Marker({
            position: location,
            map: map,
            icon: image,
            optimized: false
        });

        bounds.extend(location);

        google.maps.event.addListener(marker, 'click', function() {
            window.location = 'http://maps.google.com?q='+location.url;
        });

        google.maps.event.addDomListener(window, 'resize', function() {
            var center = map.getCenter();
            google.maps.event.trigger(map, "resize");
            map.setCenter(center);
        });

        map.setCenter(bounds.getCenter());

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
